<!--
<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form (ngSubmit)="loginByAuth()" [formGroup]="loginForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="email"
          placeholder="Email"
          type="email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input id="remember" type="checkbox"/>
            <label for="remember"> Remember Me </label>
          </div>
        </div>
        &lt;!&ndash; /.col &ndash;&gt;
        <div class="col-5">
          <pf-button
            [block]="true"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
            Sign In
          </pf-button>
        </div>
        &lt;!&ndash; /.col &ndash;&gt;
      </div>
    </form>

    <div class="social-auth-links text-center mb-3">
      <pf-button
        (click)="loginByFacebook()"
        [block]="true"
        [disabled]="isAuthLoading || isGoogleLoading"
        [loading]="isFacebookLoading"
        class="mb-2"
      >
        <i class="fab fa-facebook mr-2"></i>
        Sign in using Facebook
      </pf-button>
      <pf-button
        (click)="loginByGoogle()"
        [block]="true"
        [disabled]="isAuthLoading || isFacebookLoading"
        [loading]="isGoogleLoading"
        theme="danger"
      >
        <i class="fab fa-google mr-2"></i>
        Sign in using Google+
      </pf-button>
    </div>

    <p class="mb-1">
      <a [routerLink]="['/forgot-password']">I forgot my password</a>
    </p>
    <p class="mb-0">
      <a [routerLink]="['/register']" class="text-center">
        Register a new membership
      </a>
    </p>
  </div>
  &lt;!&ndash; /.login-card-body &ndash;&gt;
</div>
-->
<!--<div class="back-ground">
<div class="con">
    <h5 class="text-center mb-1 h5">Login</h5>
    <div class="mt-2" style="border-color:#e9ecef !important;border:1px solid;padding: 10px;">
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
        <div class="form-group">
            <input type="text" autocomplete="nope" class="form-control" formControlName="username" id="username" placeholder="Email ID">
        </div>
        <div class="form-group">
            <div class="input-group">
                <input type="password" class="form-control" formControlName="password" placeholder="Password" id="password">
                <div class="input-group-append">
                    <span class="input-group-text rounded-0"><i class="fa fa-eye" id="eye" data-placement="top" title="Click to Show Password" aria-hidden="true"
                      (click)="togglePasswordVisibility()"
                      [ngClass]="{'fa-eye-slash': isPasswordVisible, 'fa-eye': !isPasswordVisible}" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button type="submit" class="btn login-btn">Login</button>
        </div>
        <div class="text-center mt-2">
            <button type="button" class="btn login-btn" (click)="register()">Processor Register</button>
        </div>
        </form>
    </div>
</div>
</div>-->

<div class="container-fluid col-lg-10 col-12">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12 mt-5 mb-5">
            <div class="row mt-3 mb-5">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <img src="assets/img/TF2T%20approved%20logo.png"  class="img-fluid p-4 mt-3" style="height: 150px;width: 500px">
                    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                        <div class="form-group mt-lg-5">
                            <input type="text" autocomplete="nope" class="form-control" formControlName="username" id="username" placeholder="Email ID">
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="password" class="form-control" formControlName="password" placeholder="Password" id="password">
                                <div class="input-group-append">
                    <span class="input-group-text rounded-0"><i class="fa fa-eye" id="eye" data-placement="top" title="Click to Show Password" aria-hidden="true"
                                                                (click)="togglePasswordVisibility()"
                                                                [ngClass]="{'fa-eye-slash': isPasswordVisible, 'fa-eye': !isPasswordVisible}" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn login-btn">Login</button>
                        </div>
                        <div class="text-center mt-2">
                            <button type="button" class="btn login-btn" (click)="register()">Processor Register</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="row mt-5">
                <div class="col-lg-12 mt-5">
                    <img src="assets/img/Logo_1090%20462.png" class="img-fluid login-img mt-5">
                </div>
            </div>
        </div>
    </div>
</div>


<footer class="fixed-bottom" style="background-color: lightgoldenrodyellow">
    <!--<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>-->
    <div class="text-center">
        <strong>
            <span>Copyright &copy; {{ currentYear }}</span>
            <a
                    href="https://varnikcloud.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="margin: 0"
            >
            varnik.cloud</a
            >
            <span>.</span>
        </strong>
        <span> All rights reserved.</span>
    </div>
</footer>