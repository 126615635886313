import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {ProcessorManagementComponent} from '@components/processor-management/processor-management.component';
import {ActivityCreationComponent} from '@components/activity-creation/activity-creation.component';
import {DailyActivityCreationComponent} from "@components/daily-activity-creation/daily-activity-creation.component";
import { ActivityMappingComponent } from '@components/activity-mapping/activity-mapping.component';
import {AddActivityComponent} from "@components/add-activity/add-activity.component";
import {FinalQrComponent} from "@components/final-qr/final-qr.component";
import {QrGenerationComponent} from "@components/Qr-generation/qr-generation.component";
import {FinalActivityScreenComponent} from "@components/final-activity-screen/final-activity-screen.component";
import {FinalLabelPrintComponent} from "@components/final-label-print/final-label-print.component";
import { AllzProductDesign } from '@components/allz-product-design/allz-product-design.component';

const routes: Routes = [
    {
        path: 'main',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: 'dash',
                component: DashboardComponent
            },
            {
                path: 'processor-management',
                component: ProcessorManagementComponent,
            },
            {
                path: 'activity-mapping',
                component: ActivityMappingComponent,
            },
            {
                path: 'activity-creation',
                component: ActivityCreationComponent,
            },
            {
                path: 'daily-activity-creation',
                component: DailyActivityCreationComponent
            },
            {
                path: 'add-activity',
                component: AddActivityComponent
            },
            {
                path: 'qr-generation',
                component: QrGenerationComponent
            },
            {
                path: 'final-activity',
                component: FinalActivityScreenComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'final-qr',
        component: FinalQrComponent
    },
    {
      path: 'final-label-print',
      component: FinalLabelPrintComponent
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent
    },
    {
        path: 'product-history',
        component: AllzProductDesign
    },
    {path: '**', redirectTo: 'login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
