<section class="one-page" style="overflow: hidden">
  <div class="container-fluid">
    <div class="row">
      <!-- nav -->
      <nav class="navbar bg-body-tertiary shadow-sm">
        <div class="container-fluid d-flex justify-content-start">
          <!-- First Logo -->
          <a href="https://geotracing.varnikcloud.com/#/login" target="_blank">
            <img
              src="/assets/img/Logo_1090%20462.png"
              width="120"
              class="img-fluid mr-lg-3"
              alt="Allz Store Logo 1"
            />
          </a>
          <!-- Second Logo -->
          <a href="https://allz.store/#/" target="_blank">
            <img
              src="/assets/img/allz_2-old.png"
              width="100"
              class="img-fluid"
              alt="Allz Store Logo 2"
            />
          </a>
        </div>
      </nav>
      

      <div class="col-lg-1"></div>
      <div class="col-lg-10 mt-3">
        <div class="product-container py-2">
          <!-- Header Section -->
          <div
            class="text-center mb-4"
            data-aos="fade-right"
            data-aos-offset="500"
            data-aos-easing="ease-in-sine"
          >
            <h2 class="fw-bold amatic-sc-bold">
              {{ this.details?.productName }}
            </h2>
            <div class="image-wrapper position-relative d-inline-block">
              <img
                *ngIf="details?.productId"
                [src]="
                  'https://ttomcat4.varnik.cloud:8443/allz-ws-prod/api/getProductImage/' +
                  details.productId
                "
                class="img-fluid product-image"
                alt="Product Image"
                loading="lazy"
              />
              <img
                src="../../../assets/img/loading.gif"
                *ngIf="!details?.productId"
                class="img-fluid"
                style="height: 80px; width: 80px"
              />
            </div>
          </div>

          <!-- Product Image -->
          <!-- <div
            class="text-center mb-3"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src="../../../assets/img/n.png"
              alt="Product Image"
              class="img-fluid productImage"
            />
          </div> -->

          <!-- Seller Details -->
          <div class="p-lg-4" data-aos="zoom-in">
            <div class="row">
              <!-- <div class="seller-logo mb-3 col-12 col-lg-3">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhg-n4OxJfw_JHK5gDxEWuM6qXSQJ2woTfvQ&s" alt="Seller Logo" class="img-fluid" style="height: 100px; width: 100px;">
            </div> -->
              <div class="col-12 col-lg-12">
                <h3 class="section-title">About the Seller</h3>
                <div class="sellerLogo d-flex mb-3 col-12 col-lg-3">
                  <img
                    *ngIf="details?.shopId"
                    [src]="
                      'https://ttomcat4.varnik.cloud:8443/allz-ws-prod/api/shop/profile/' +
                      details.shopId
                    "
                    alt="SellerLogo"
                    class="img-fluid"
                  />
                  <img
                    src="../../../assets/img/loading.gif"
                    *ngIf="!details?.shopId"
                    class="img-fluid"
                    style="height: 30px; width: 30px"
                  />
                </div>
                <p
                  class="body-text nunito-regular"
                  [innerHTML]="about ?? ''"
                ></p>
              </div>
            </div>
          </div>

          <!-- Product Details -->
          <div
            class="nutrition-table row mb-3"
            data-aos="zoom-in"
          >
            <div class="col-md-6 mb-3" data-aos="fade-right">
              <div class="p-4 border">
                <h6
                  class="d-flex align-items-center justify-content-between cursor-pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#nutrition"
                  aria-expanded="false"
                  aria-controls="nutrition"
                  (click)="toggleSection('nutrition')"
                >
                  Nutrition Details
                  <i
                    [class]="
                      barChartExpanded
                        ? 'bi bi-chevron-up'
                        : 'bi bi-chevron-down'
                    "
                  ></i>
                </h6>
                <div class="collapse show mt-3" id="nutrition">
                  <table class="table table-borderless">
                    <tr>
                      <td></td>
                      <th>Per 100g</th>
                    </tr>
                    <tr *ngFor="let nutrition of details?.nutrientList">
                      <th>{{ nutrition.nutrientName }}</th>
                      <td>{{ nutrition.value }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" *ngIf="details?.productIngredients != '' && details?.productIngredients != ' '">
              <div class="p-4 border">
                <h6
                  class="d-flex align-items-center justify-content-between cursor-pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#ingredients"
                  aria-expanded="false"
                  aria-controls="ingredients"
                  (click)="toggleSection('ingredients')"
                >
                  Ingredients
                  <i
                    [class]="
                      ingredientsExpanded
                        ? 'bi bi-chevron-up'
                        : 'bi bi-chevron-down'"
                  ></i>
                </h6>
                <p class="collapse show mt-3" id="ingredients">
                  {{ details?.productIngredients }}
                </p>
              </div>
            </div>
          </div>

          <!-- Storyline Section -->
          <div
            class="storyline-section"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <h3 class="section-title">About Our Story</h3>
            <p class="body-text">{{ this.details?.productStory }}</p>
          </div>

          <!-- Help Section -->
          <!-- <div class="certificate-section mt-2" *ngIf="this.fssai || this.fda || this.iso || this.halal">
            <h3 class="mb-5 section-title">Product Certificates</h3>

            <swiper [config]="isMobileNewProduct" class="certificate-swiper container-fluid">
              <ng-template swiperSlide *ngFor="let certificate of certificates">
                <div class="certificate-slide text-center">
                  <img
                    [src]="certificate.imageUrl"
                    [alt]="certificate.alt"
                    class="img-fluid"
                    width="170"
                    height="170"
                  />
                  <p class="certificate-number mb-0 mt-3">{{ certificate.number }}</p>
                </div>
              </ng-template>
            </swiper>
            
          </div> -->
          <div class="certificate-section mt-2" *ngIf="certificates.length > 0">
            <h3 class="mb-5 section-title">Product Certificates</h3>
          
            <swiper [config]="isMobileNewProduct" class="certificate-swiper container-fluid">
              <ng-template swiperSlide *ngFor="let certificate of certificates">
                <div class="certificate-slide text-center">
                  <img
                    [src]="certificate.imageUrl"
                    [alt]="certificate.alt"
                    class="img-fluid"
                    width="170"
                    height="170"
                  />
                  <p class="certificate-number mb-0 mt-3">{{ certificate.number }}</p>
                </div>
              </ng-template>
            </swiper>
          </div>
          
          <!-- Footer Section -->
          <!-- <div class="footer-section text-center">
            <p>&copy; 2025 Your Company Name. All Rights Reserved.</p>
          </div> -->
        </div>

        <div class="product-container py-2 mt-4">
          <h3 class="mb-4 section-title">About Allz Store</h3>

          <!-- Header Section -->
          <div class="text-center mb-2">
            <img
              src="../../../assets/img/allz_2-old.png"
              alt="Logo"
              style="height: 50px"
              class="mb-3"
            />
          </div>

          <!-- Seller Details -->
          <div class="mb-3">
            <div class="row">
              <p class="fw-bold sub-title text-black mb-2">
                Your Trusted Partner for Premium Indian Products in the USA
              </p>
              <p class="body-text">
                At <b class="fw-bold">Allz.Store,</b> we bring you the rich
                heritage of India through an exclusive range of agricultural and
                household products. Rooted in quality and tradition, our
                products are directly sourced from trusted Indian suppliers,
                ensuring authenticity and excellence in every delivery.
              </p>
            </div>
          </div>

          <!-- Storyline Section -->
          <div class="storyline-section">
            <h3>Our Vision</h3>
            <p class="body-text">
              To bridge the gap between Indian tradition and global consumers by
              offering high-quality, culturally significant products that
              enhance lifestyles and homes across the USA.
            </p>
          </div>

          <!-- why choose -->
          <div class="chooseUs mt-5">
            <div class="">
              <h3>Why Choose Us?</h3>
              <div class="row">
                <div class="col-lg-6 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-shield-check text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/award.png"
                        width="30"
                        height="30"
                        alt="Authenticity"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Authenticity Guaranteed</b>
                    </p>
                    <p class="body-text">
                      We source our products directly from India, ensuring every
                      item reflects its true heritage.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-gem text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/premium-quality.png"
                        width="30"
                        height="30"
                        alt="Quality"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Premium Quality</b>
                    </p>
                    <p class="body-text">
                      From spices and grains to handcrafted household
                      essentials, we offer only the best.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-truck text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/delivery-truck.png"
                        width="30"
                        height="30"
                        alt="Delivery"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Seamless Delivery</b>
                    </p>
                    <p class="body-text">
                      Convenient online shopping experience with timely delivery
                      across the USA.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-emoji-smile text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/satisfaction (1).png"
                        width="30"
                        height="30"
                        alt="Satisfaction"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Customer Satisfaction</b>
                    </p>
                    <p class="body-text">
                      Dedicated to providing exceptional service and fostering
                      trust with our customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- product range -->
          <div class="productRange">
            <div class="">
              <h3>Our Product Range</h3>
              <div class="row mb-3">
                <!--         
                <div class="col-lg-12 mb-2 col-12">
                  <div class="mb-2 rounded-0 p-2">
        
                    <p class="text-center sub-title">
                      <b>Agricultural Products</b> <i class="bi bi-sun ml-2 text-dark"></i>
                    </p>
                    <p class="text-center body-text">
                      Organic spices, pulses, rice, wheat, and more.
                    </p>
        
                  </div>
                </div> -->

                <div class="col-lg-4 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-sun text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/commodity.png"
                        width="30"
                        height="30"
                        alt="Satisfaction"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Agricultural Products</b>
                    </p>
                    <p class="body-text">
                      Organic spices, pulses, rice, wheat, and more.
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-house text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/house.png"
                        width="25"
                        height="25"
                        alt="Satisfaction"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Household Essentials</b>
                    </p>
                    <p class="body-text">
                      Traditional cookware, handcrafted decor, and utility
                      items.
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 choose mb-3 col-12" data-aos="zoom-in">
                  <div class="custom-card p-4 text-center">
                    <div class="icon-container">
                      <!-- <i class="bi bi-gift text-dark"></i> -->
                      <img
                        src="../../../assets/img/icons/package.png"
                        width="30"
                        height="30"
                        alt="Satisfaction"
                      />
                    </div>
                    <p class="sub-title">
                      <b>Exclusive Indian Offerings</b>
                    </p>
                    <p class="body-text">
                      Specialty items like Ayurvedic products, pickles, and
                      festival essentials.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- how-order -->
          <div class="how-order mt-3">
            <h3 class="">How to Order from Allz.Store</h3>

            <div class="row">
              <p class="fw-bold mt-3 mb-4">
                Your Trusted Partner for Premium Indian Products in the USA
              </p>
            </div>

            <div class="row text-center">
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/ux.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2">
                  <a
                    href="https://allz.store/#/"
                    target="_blank"
                    class="fw-bold"
                    >www.allz.store</a
                  >
                </p>
                <img
                  src="../../../assets/img/icons/next.png"
                  width="10"
                  height="10"
                  class="img-fluid next-arrow"
                  alt="next.png"
                />
              </div>
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/trolley.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2 fw-bold">Add to cart</p>
                <img
                  src="../../../assets/img/icons/next.png"
                  width="10"
                  height="10"
                  class="img-fluid next-arrow"
                  alt="next.png"
                />
              </div>
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/self-checkout.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2 fw-bold">Secure Checkout</p>
                <img
                  src="../../../assets/img/icons/next.png"
                  width="10"
                  height="10"
                  class="img-fluid next-arrow"
                  alt="next.png"
                />
              </div>
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/secure-payment.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2 fw-bold">Secure Payment</p>
                <img
                  src="../../../assets/img/icons/next.png"
                  width="10"
                  height="10"
                  class="img-fluid next-arrow"
                  alt="next.png"
                />
              </div>
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/successful.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2 fw-bold">Order placed</p>
                <img
                  src="../../../assets/img/icons/next.png"
                  width="10"
                  height="10"
                  class="img-fluid next-arrow"
                  alt="next.png"
                />
              </div>
              <div class="col-lg-2 col-12 mb-4 order-box">
                <img
                  src="../../../assets/img/icons/order.png"
                  width="40"
                  height="40"
                  alt="website"
                />
                <p class="mt-2 fw-bold">Delivered Successfully</p>
              </div>
            </div>
          </div>

          <!-- contact -->
          <div class="contact">
            <h3>Need Help?</h3>

            <div>
              <span class="contacts">
                Email:
                <a
                  href="mailto:contact@allz.store"
                  target="_blank"
                  class="fw-bold"
                  >contact&#64;allz.store</a
                >
              </span>
            </div>

            <div>
              <span class="contacts">
                Call:
                <a href="tel:+1 50891 80470" target="_blank" class="fw-bold"
                  >+1 508 918 0470</a
                >
              </span>
            </div>
          </div>

          <!-- Footer Section -->
          <!-- <div class="footer-section mt-5 text-center"> -->
          <!-- <p>&copy; 2025 Your Company Name. All Rights Reserved.</p> -->
          <!-- <p>
              Allz.Store – Making Indian Heritage Accessible in Every Home
              Across the USA.
            </p>
          </div> -->
        </div>
      </div>

      <div class="col-lg-1"></div>
    </div>
  </div>
</section>

<footer>
  <hr />

  <div class="container-fluid mb-2">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <div class="text-center">
            <strong>
             Copyright &copy; {{ currentYear }}
             <a
             href="https://geotracing.varnikcloud.com/#/login"
             target="_blank"
             rel="noopener noreferrer"
           >
             GeoTracing
           </a>
           &
              <a
                href="https://allz.store/#/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Allz.store
              </a>
              . All rights reserved.
            </strong>
          </div>

          <div class="text-center">
            <strong>
              Designed and developed by 
              <a
                href="https://varnikcloud.com"
                target="_blank"
                rel="noopener noreferrer"
              >
              varnik.cloud
              </a>
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<ngx-spinner type="ball-scale-multiple">
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
