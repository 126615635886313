import { ProductDetails } from "@/model/productDetails/productDetails";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import * as CryptoJS from 'crypto-js';
import { AllzProductDetails } from "@/model/allzProductDetails/productDetails";
import * as AOS from 'aos';
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { NgxSpinnerService } from "ngx-spinner";
import {DateTime} from 'luxon';


SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade, EffectCube, EffectCoverflow, EffectFlip]);

@Component({
  selector: 'app-allz-product-design',
  templateUrl: './allz-product-design.component.html',
  styleUrls: ['./allz-product-design.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AllzProductDesign implements OnInit {

  // Api variables
  destroy$: Subject<boolean> = new Subject<boolean>();
  details: AllzProductDetails;
  decryptedData: any;
  secretKey = "NMGyp36TQwPvs4Qq4yvlsTq8tAh4PE4d";
  about: String;

  // certificate
  fssai:String;
  halal:String;
  iso:String;
  fda:String;



  // image placeholder
  placeholderImage = 'assets/img/loading.gif'

  // current year
  public currentYear: string = DateTime.now().toFormat('y');

  certificates: Array<{ imageUrl: string; alt: string; number: string }> = [];


  // collapse
  barChartExpanded: boolean = false;
  ingredientsExpanded: boolean = false;
  orderExpanded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }


  isMobileNewProduct: SwiperOptions = {
    slidesPerView: 2,
    loop: false,
    spaceBetween: 20,
    autoplay: false,
    breakpoints: {
      1440: { slidesPerView: 4, spaceBetween: 10 },
      1024: { slidesPerView: 4, spaceBetween: 10 },
      700: { slidesPerView: 4, spaceBetween: 10 },
      500: { slidesPerView: 2.1, spaceBetween: 30 },
      400: { slidesPerView: 2.1, spaceBetween: 30 },
      300: { slidesPerView: 2.1, spaceBetween: 30 },
    },
    navigation: {
      nextEl: '.custom-swiper-button-next',
      prevEl: '.custom-swiper-button-prev',
    },
  };







  ngOnInit(): void {
    this.spinner.show();
    AOS.init();
    this.activatedRoute.queryParams.subscribe(params => {
      const encryptedData = params['id'];

      if (encryptedData) {
        // Decrypt the data
        this.decryptedData = this.decryptData(encryptedData);
        console.log('Decrypted Data:', this.decryptedData);
        this.getProduct(this.decryptedData);
      } else {
        console.error('No data found in the QR code.');
      }
    });
  }



  decryptData(encryptedData: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedString); // Convert back to JSON object
    } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
    }
  }


  getProduct(id) {
    this.spinner.show();  // Show loading spinner
    this.appService.getProductDetails(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.details = data;
        this.getAbout(this.details.createdBy)
        console.log('Product details: ', this.details);
      }, (err: any) => {
        this.spinner.hide()
        console.log("something wrong :", err)
      });
  }
  getAbout(userid): void {
    this.spinner.show(); // Show loading spinner
    this.appService.getAbout(userid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log('About details: ', data);
          this.spinner.hide(); // Hide loading spinner
  
          // Assigning data to respective variables
          this.about = data.about;
          this.fssai = data.fssaiCertificationNumber;
          this.fda = data.fdaCertificationNumber;
          this.iso = data.isoCertificationNumber;
          this.halal = data.halalCertificationNumber;
  
          // Creating certificates array dynamically
          this.certificates = [
            {
              imageUrl: 'assets/img/certificate/fssai .png',
              alt: 'FSSAI Certificate',
              number: data.fssaiCertificationNumber || '',
            },
            {
              imageUrl: '../assets/img/certificate/fda.png',
              alt: 'FDA Certificate',
              number: data.fdaCertificationNumber || '',
            },
            {
              imageUrl: '../assets/img/certificate/halal.png',
              alt: 'Halal Certificate',
              number: data.halalCertificationNumber || '',
            },
            {
              imageUrl: '../assets/img/certificate/iso.png',
              alt: 'ISO Certificate',
              number: data.isoCertificationNumber || '',
            },
          ].filter(certificate => certificate.number.trim().length > 0); // Filter out empty certificate numbers
        },
        (error) => {
          this.spinner.hide(); // Hide spinner on error
          console.error('Error fetching about details: ', error);
        }
      );
  }
  
  





  toggleSection(section: string): void {
    if (section === 'nutrition') {
      this.barChartExpanded = !this.barChartExpanded;
    } else if (section === 'ingredients') {
      this.ingredientsExpanded = !this.ingredientsExpanded;
    } else if (section === 'order') {
      this.orderExpanded = !this.orderExpanded;
    }
  }


}